import { SearchFilter } from "../types/filters";
import { getRequestObject, getRequestString, postDataRaw } from "./base";

export function getShortFilters(
  siteId: string,
  initialFilters: string[],
  filterParams: any,
  language?: string,
): Promise<SearchFilter[] | null> {
  const API = `${process.env.SITE_BUILDER_EXTERNAL_PATH}/${siteId}/filtersShort/`;

  return getRequestObject<SearchFilter[]>(API, { ...filterParams, lang: language, filters: initialFilters.join(",") });
}

export async function getCreditPaymentPerMonthWithoutTax(
  price: number,
  taxbableFees: number,
  termsInMoths: number,
  apr: number,
  tradeIn: number,
  tradeInOwed: number,
  downPayment: number,
): Promise<number> {
  const API = `${process.env.FINANCE_PATH}/finance/rawMonthPaymentWithoutTax`;
  const strValue = await getRequestString(API, {
    price,
    apr,
    terms: termsInMoths,
    tradeIn,
    tradeInOwed,
    downPayment,
    dealerFees: taxbableFees,
  });
  return strValue ? parseFloat(strValue) : 0;
}

import {
  CustomLeadData,
  EmploymentAddLead,
  FinanceLead,
  LeadData,
  OrderPartsLead,
  ServiceLeadData,
  ValueYourTradeAddLead,
  VehicleLeadData,
} from "../types/lead";
import { postDataObject } from "./base";
import { ActionButtonData } from "../types/site";
import { SearchResponse, Vehicle } from "../types/vehicle";
import { prefetchActionButtonData } from "../utils/actionButtons";
import { VerificationRequest } from "../types/verification";

export async function addVehicleLead(dealerId: string, leadData: VehicleLeadData): Promise<LeadData> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/vehicle`;

  return postDataObject<LeadData>(API, leadData);
}

export async function addServiceLead(dealerId: string, leadData: ServiceLeadData): Promise<LeadData> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/service`;

  return postDataObject<LeadData>(API, leadData);
}

export async function addContactLead(dealerId: string, leadData: LeadData): Promise<LeadData> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/contact`;
  return postDataObject<LeadData>(API, leadData);
}

export async function addCustomLead(dealerId: string, leadType: string, leadData: CustomLeadData): Promise<LeadData> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/custom/${leadType}`;
  return postDataObject<LeadData>(API, leadData);
}

export async function addFinanceLead(dealerId: string, leadData: FinanceLead): Promise<FinanceLead> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/finance`;
  return postDataObject<FinanceLead>(API, leadData);
}

export async function addEmploymentLead(dealerId: string, leadData: EmploymentAddLead): Promise<any> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/employment`;
  return postDataObject<any>(API, leadData);
}

export async function addPartsLead(dealerId: string, leadData: OrderPartsLead): Promise<OrderPartsLead> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/parts`;
  return postDataObject<OrderPartsLead>(API, leadData);
}

export async function addValueYouTradeLead(dealerId: string, leadData: ValueYourTradeAddLead): Promise<any> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/tradeIn`;
  return postDataObject(API, leadData);
}

export function getMultipleVehicles(
  siteId: string,
  vehicleIds: string[],
  actionButtons?: ActionButtonData[],
): Promise<Vehicle[]> {
  const API = `${process.env.SITE_BUILDER_EXTERNAL_PATH}/${siteId}/vehicles`;

  return postDataObject(API, vehicleIds).then((res) => {
    if (actionButtons) {
      return prefetchActionButtonData(res, actionButtons).then(() => res);
    }
    return res;
  });
}

export function getSearchResponse(siteId: string, text: string, limit: number): Promise<SearchResponse | null> {
  const API = `${process.env.SITE_BUILDER_EXTERNAL_PATH}/${siteId}/search`;
  return getRequestObject<SearchResponse>(API, { text, limit });
}

export async function sendVerificationCode(data: VerificationRequest): Promise<string> {
  const API = `${process.env.VERIFICATOR_PATH}`;
  const response = await fetch(API, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
  return await response.text();
}

export async function checkVerificationCode(verificationId: string, code: number | string): Promise<string> {
  const API = `${process.env.VERIFICATOR_PATH}`;
  const response = await fetch(`${API}/${verificationId}/check?code=${code}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return await response.text();
}

export interface SpecialCouponData {
  specialValue: string;
  specialDescription: string;
  barcodeMessage?: string;
  expires?: string;
  description?: string;
  shortDescription?: string;
  terms?: string;
}

export async function createGoogleWalletSpecials(siteId: string, couponData: SpecialCouponData): Promise<string> {
  const API = `${process.env.SITE_BUILDER_EXTERNAL_PATH}/${siteId}/specialBonusPass?type=android`;
  const result = await postDataRaw(API, couponData);

  return new TextDecoder().decode(result);
}

export async function createAppleWalletSpecials(siteId: string, couponData: SpecialCouponData): Promise<ArrayBuffer> {
  const API = `${process.env.SITE_BUILDER_EXTERNAL_PATH}/${siteId}/specialBonusPass?type=ios`;
  const result = await postDataRaw(API, couponData);

  return result;
}
