import { createFinalUrl } from "../utils/url";

const REQUEST_TIMEOUT = 7000;

export async function getRequestObject<T = any>(url: string, params?: any, headers?: any): Promise<T | null> {
  const result = await getRequestString(url, params, headers);
  if (result) {
    return JSON.parse(result);
  }

  return null;
}

export async function getRequestString(url: string, params?: any, headers?: any): Promise<string | null> {
  const finalUrl = createFinalUrl(url, params);
  const requestOptions: RequestInit = {
    headers,
    cache: "no-cache",
    signal: AbortSignal.timeout(REQUEST_TIMEOUT),
  };

  const response = await fetch(finalUrl, requestOptions);

  if (response.ok) {
    return response.text();
  } else if (response.status === 404) {
    return null;
  }

  throw new Error(`could not load url ${url} params ${JSON.stringify(params)} response code ${response.status}`);
}

export function postDataObject<T = any>(url: string, data: any): Promise<T> {
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(`could not post url ${url} response code ${response.status}`);
  });
}

export function postDataRaw(url: string, data: any): Promise<ArrayBuffer> {
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    if (response.ok) {
      return response.arrayBuffer();
    }
    throw new Error(`could not post url ${url} response code ${response.status}`);
  });
}
