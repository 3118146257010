export function createFinalUrl(url: string, params?: any): string {
  let updatedParams: any;
  if (params) {
    updatedParams = {};
    Object.keys(params).forEach((key) => {
      if (params[key] !== undefined) {
        updatedParams[key] = params[key];
      }
    });
  }
  const queryParamsString = updatedParams ? "?" + new URLSearchParams(updatedParams).toString() : "";
  return url + queryParamsString;
}
